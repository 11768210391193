import initState from '@shared/init-state-v0.1.0'
import type { BaseJsProps } from '@shared/node-v1.0.0'
import type { JsNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'
import { unSubscribeAll } from '../component/handleSubscribe'
import getStore from './store'
import type { Store } from './store'

export type Props = BaseJsProps & BaseProps & { store: Store }

export type BaseProps = {
	query?: any
	liveTables?: string[]
}

export default {
	hashTag: '#experimental',
	module: { dynamic: import('../component/useData') },
	inNode: {
		inputs: [
			getPortDef({
				name: 'query',
				displayName: 'Query',
				group: 'Params',
				type: '*',
				validate: (p: Props) => Boolean(p.query),
			}),
			getPortDef({
				name: 'liveTables',
				displayName: 'Live tables',
				group: 'Custom',
				customGroup: 'Live tables',
				type: 'proplist',
			}),
		],
		outputs: [
			getPortDef({ name: 'fetching', displayName: 'Fetching', group: 'States', type: 'boolean' }),
			getPortDef({ name: 'fetched', displayName: 'Fetched', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'response', displayName: 'Response', group: 'Data', type: 'array' }),
		],
	},
	afterNode: {
		triggerOnInputs: () => ['query', 'liveTables'],
		getInspectInfo: (p: Props, _, noodlNode) => [{ type: 'text', value: `fetching: ${noodlNode._internal.fetching}` }],
	},
	beforeComponent: {
		initialize: async (p: Props, noodlNode) => {
			await initState('initialized')
			p.store = getStore(p)

			// Отпишемся, когда родитель отмонтировался. Родитель может быть страницей, в таком случае пропустим.
			if (noodlNode.nodeScope.componentOwner.parent?.innerReactComponentRef)
				noodlNode.nodeScope.componentOwner.parent.innerReactComponentRef.componentWillUnmount = () => unSubscribeAll(p)
			// Отпишемся, когда удален.
			noodlNode._onNodeDeleted = () => unSubscribeAll(p)
		},
	},
	disableCustomProps: true,
} satisfies JsNodeDef
