import type { DocumentNotification } from '@nodes/app-v2.0.0'
import { getDbClassName, getVersionedDbClass } from '@shared/db-class'
import { getKuzzle } from '@shared/get-kuzzle'
import type { NoodlNode } from '@shared/node-v1.0.0'
import type { Item } from '@shared/types-v0.1.0'
import type { Props } from '../node/definition'
import fetchBySub from './fetchBySub'
import handleDataChanges from './handleDataChanges'
import setItem from './setItem'

export type Notification = DocumentNotification & { result: { _updatedFields: string[] } }

// Базируется на hash каждой схемы класса.
// hash характерен для каждой схемы, прилетает с Kuzzle и используется только для подписок.
// Если хеш есть в подписках и есть в данных, значит схема не изменилась - пропускаем.
// Если хеш есть в подписках, но нет в данных, значит старая схема изменилась - отписываемся.
// Если хеша нет в подписках, но есть в данных, значит это новая схема - подписываемся.
export const handleSubscribe = async (p: Props, noodlNode: NoodlNode) => {
	// Не даем сработать подписке, если вкдлючен режим controlled
	if (p.controlled) return

	// Нужно убрать дубли, чтобы не подписываться на то, на что уже подписаны, так как из разных мест (useData)
	// могут прилетать одинаковые схемы с одинаковым hashем
	const schemesData = R.libs.remeda.uniqueWith(p.store.schemesData, (a, b) => a.schemeHash === b.schemeHash)
	const subscribes = p.store.subscribes

	// Отписка от больше не существующих схем.
	subscribes.forEach((_, schemeHash) => {
		if (!schemesData.find((i) => i.schemeHash === schemeHash)) unSubscribeFromScheme(p, schemeHash)
	})

	// Подписка на новые схемы.
	for (const schemeData of schemesData) {
		// schemeData.channel - канал для подписки, прилетает от сервера
		// !subscribes.has(schemeData.schemeHash) - если ещё не подписались
		if (schemeData.channel && !subscribes.has(schemeData.schemeHash)) {
			await subscribeOnScheme(p, noodlNode, schemeData.schemeHash, schemeData.channel)
		}
	}
}

// Команда отписаться от всего, когда useData переходит в контроллируемый режим
export const unsubscribe = (p: Props) => p.store.subscribes.forEach((_, schemeHash) => unSubscribeFromScheme(p, schemeHash))

// Подписываемся на схему
const subscribeOnScheme = async (p: Props, noodlNode: NoodlNode, schemeHash: string, channel: string) => {
	const { dbName } = window.R.env
	if (!dbName) return

	const K = await getKuzzle()
	if (!K) return

	const scheme = p.store.schemesData.find((i) => i.schemeHash === schemeHash)?.scheme
	if (scheme) {
		const dbClassName = getDbClassName(scheme.dbClass)
		const dbClassV = getVersionedDbClass(scheme.dbClass)

		if (dbClassV) {
			const notify = (notif: Notification) => {
				if (notif.type !== 'document') return
				handleNotification(p, noodlNode, schemeHash, notif)

				log.info(`Subscribe - ${notif.action} ${dbClassName}: `, notif.result)
			}

			// Передаем канал и функцию подписки
			K.protocol.on(channel, notify)

			p.store.subscribes.set(schemeHash, { channel, notify })
			log.info(`Subscribed to "${dbClassName}"`, { schemeHash, scheme })
		}
	}
}

// Отписка
const unSubscribeFromScheme = async (p: Props, schemeHash: string) => {
	const { dbName } = window.R.env
	if (!dbName) return

	const K = await getKuzzle()
	if (!K) return

	const unsub = p.store.subscribes.get(schemeHash)
	if (unsub) {
		K.protocol.removeListener(unsub.channel, unsub.notify)
		p.store.subscribes.delete(schemeHash)

		log.info(`Unsubscribed from schemeHash "${schemeHash}"`)
	}
}

// Поскольку подписки идут по всем схемам, можно обрабатывать item найденный в этой схеме.
// Тоесть можно по подписке отловить изменение любого item, и обновить только необходимую компоненту!
// Дубли отработает тригер другой схемы.
export const handleNotification = async (p: Props, noodlNode: NoodlNode, schemeHash: string, notif: Notification) => {
	const sort = R.libs.sort
	const { get } = R.libs.just

	const schemesData = p.store.schemesData.filter((i) => i.schemeHash === schemeHash)
	const itemId = notif.result._id

	if (schemesData?.length) {
		// Берем из notificationф rawItem (сырой item)
		const rawItem = {
			...notif.result._source,
			dbClass: getDbClassName(schemesData[0].scheme.dbClass),
			id: itemId,
		} as Item

		// Если in - то данные остались или только попали в рамках фильтра
		if (notif.scope === 'in') {
			// Обновление существующего item.
			// Если об уже был в расках фильтра
			if (itemId && schemesData[0].itemIds.includes(itemId)) {
				const item = R.items[itemId]

				if (item) setItem(rawItem, p.store.rootId)

				// Нужно сменить сортировку в itemIds.
				const sorts = schemesData[0].scheme.sorts
				if (sorts) {
					let items = schemesData[0].itemIds.map((id) => R.items[id]).filter((i) => i !== undefined)
					items = sort(items).by(sorts.map((s) => ({ [Object.values(s)[0]]: (i: any) => get(i, Object.keys(s)[0]) }) as any))
					for (const d of schemesData) d.itemIds = items.map((i) => i.id)
				}
			} else {
				// Если его не было в рамках фильтра, и он только попал
				// Добавление нового item.
				setItem(rawItem, p.store.rootId)
				for (const schemeData of schemesData) schemeData.itemIds.push(rawItem.id)

				// Нужно сменить сортировку в itemIds.
				const sorts = schemesData[0].scheme.sorts
				if (sorts) {
					let items = schemesData[0].itemIds.map((id) => R.items[id]).filter((i) => i !== undefined)
					items = sort(items).by(sorts.map((s) => ({ [Object.values(s)[0]]: (i: any) => get(i, Object.keys(s)[0]) }) as any))
					for (const d of schemesData) d.itemIds = items.map((i) => i.id)
				}
				for (const d of schemesData) d.fetched++
				for (const d of schemesData) d.total++

				// Костылечег. При добавлении нужно создать новую серверную схему и подписаться на нее.
				// При этом, не понятно как это сделать точечно. Поэтому делаем простую перезагрузку, но без тригеров и обновления выходов.
				// Так сервер подпишет на новые схемы, которые породил новый item.
				// Хоть и костыль, но без тормозов, т.к. весь код выше уже все сделал для фронта, а handleDataChanges тригернул все, что нужно.
				//setTimeout(async () => await fetchBySub(p, noodlNode), 1000)
			}
		}

		// Если out - то данные вышли за рамки фильтра
		// Удаление существующего item.
		if (notif.scope === 'out') {
			for (const d of schemesData) d.itemIds = d.itemIds.filter((id) => id !== itemId)
			for (const d of schemesData) d.fetched--
			for (const d of schemesData) d.total--
		}
	}

	// Сдался :(
	setTimeout(() => fetchBySub(p, noodlNode), 1000)
	handleDataChanges(p, noodlNode)
}
