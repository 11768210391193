import initState from '@shared/init-state-v0.1.0'
import type { BaseJsProps } from '@shared/node-v1.0.0'
import type { JsNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'

export type Props = BaseJsProps & BaseProps

export type BaseProps = {
	query?: any
}

export default {
	hashTag: '#experimental',
	module: { dynamic: import('../component/queryDb') },
	inNode: {
		inputs: [
			getPortDef({
				name: 'query',
				displayName: 'Query',
				group: 'Params',
				type: '*',
				validate: (p: Props) => Boolean(p.query),
			}),
			getPortDef({
				name: 'run',
				displayName: 'Run',
				group: 'Signals',
				type: 'signal',
			}),
		],
		outputs: [
			getPortDef({ name: 'running', displayName: 'Running', group: 'States', type: 'boolean' }),
			getPortDef({ name: 'finished', displayName: 'Finished', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'response', displayName: 'Response', group: 'Data', type: 'array' }),
		],
	},
	afterNode: {
		getInspectInfo: (p: Props, _, noodlNode) => [{ type: 'text', value: `running: ${noodlNode._internal.running}` }],
	},
	beforeComponent: {
		initialize: async () => {
			await initState('initialized')
		},
	},
	disableCustomProps: true,
} satisfies JsNodeDef
