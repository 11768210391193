import type { NoodlNode } from '@shared/node-v1.0.0'
import { sendOutput, sendSignal } from '@shared/port-send-v1.0.0'
import type { Props } from '../node/definition'

export const fetch = async (p: Props, noodlNode: NoodlNode, live?: boolean) => {
	const { db } = R.surreal

	const MantineError = R.libs.mantine?.MantineError

	const query = p.store.query

	if (!query) return

	const startTime = log.start()

	log.info('useData props', p)

	noodlNode._internal.fetching = true
	if (!live) sendOutput(noodlNode, 'fetching', true)

	let response: unknown[] | undefined

	try {
		response = await db.query(query)
		// Отловим ошибки.
	} catch (e: any) {
		log.error('useData fetch error.', e)
		MantineError?.('Системная ошибка!', `useData fetch error: ${e.message}`)
		return
	}

	noodlNode._internal.fetching = false
	sendOutput(noodlNode, 'response', response)
	if (!live) sendOutput(noodlNode, 'fetching', false)
	if (!live) sendSignal(noodlNode, 'fetched')

	log.info('useData response', response)
	log.end('useData', startTime)
}
