import { jsNode } from '@shared/node-v1.0.0'

import v200 from '@nodes/use-data-v2.0.0'
import v210 from '@nodes/use-data-v2.1.0'
import v211 from '@nodes/use-data-v2.1.1'
import v300 from '@nodes/use-data-v3.0.0'

export default jsNode(
	'useData',
	{
		'v2.0.0': v200,
		'v2.1.0': v210,
		'v2.1.1': v211,
		'v3.0.0': v300,
	},
	{ docs: 'https://docs.rolder.app/#/useData' }
)
