import initState from '@shared/init-state-v0.1.0'
import type { BaseJsProps } from '@shared/node-v1.0.0'
import type { JsNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'
import type {
	HierarchyFiltersState,
	MultiSelection,
	NodeExpansion,
	NodeMultiSelection,
	NodeSingleSelection,
	Nodes,
	RawNode,
} from '../component/Node'
import type Node from '../component/Node'
import getStore from './store'
import type { Store } from './store'

export type Props = BaseJsProps & BaseProps & { store: Store }

type SchemeWithChild = {
	getChild: (element: any, array: any[]) => RawNode
	getParent?: never
}

type SchemeWithParent = {
	getChild?: never
	getParent: (element: any, array: any[]) => RawNode
}

export type Scheme = SchemeWithChild | SchemeWithParent

export type BaseProps = {
	scheme: Scheme
	data: any[]
	filtersState?: HierarchyFiltersState
}

export type { Node, Nodes, NodeMultiSelection, NodeSingleSelection, NodeExpansion, MultiSelection }

export default {
	hashTag: '#experimental',
	module: { dynamic: import('../component') },
	inNode: {
		inputs: [
			getPortDef({
				name: 'scheme',
				displayName: 'Scheme',
				group: 'Params',
				type: 'objectEval',
				validate: (p: Props) => Boolean(p.scheme),
			}),
			getPortDef({
				name: 'data',
				displayName: 'Data',
				group: 'Data',
				type: 'array',
				validate: (p: Props) => Boolean(p.data),
			}),
			getPortDef({ name: 'filtersState', displayName: 'Filters state', group: 'Data', type: 'object' }),
			getPortDef({ name: 'resetSingleSelection', displayName: 'Reset single selection', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'resetMultiSelection', displayName: 'Reset multi selection', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'expandAll', displayName: 'Expand all', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'collapseAll', displayName: 'Collapse all', group: 'Signals', type: 'signal' }),
		],
		outputs: [
			getPortDef({ name: 'done', displayName: 'Done', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'rootId', displayName: 'Root node id', group: 'Data', type: 'string' }),
			getPortDef({ name: 'rootNode', displayName: 'Root node', group: 'Data', type: 'object' }),
			getPortDef({ name: 'singleSelectionChanged', displayName: 'Single selection changed', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'multiSelectionChanged', displayName: 'Multi selection changed', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'expansionChanged', displayName: 'Expansion changed', group: 'Signals', type: 'signal' }),
		],
	},
	afterNode: {
		triggerOnInputs: () => ['scheme', 'data', 'filtersState'],
		getInspectInfo: (p: Props) => [
			{ type: 'text', value: '=== Scheme ===' },
			{ type: 'value', value: p.scheme },
			{ type: 'text', value: '=== Filters ===' },
			{ type: 'value', value: p.filtersState },
		],
	},
	beforeComponent: {
		initialize: async (p: Props, noodlNode) => {
			await initState('initialized')
			p.store = getStore(p)
		},
	},
	disableCustomProps: true,
} satisfies JsNodeDef
